"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Base = require("../styles/Base");

var _default = ({
  onClick,
  t,
  laneId
}) => _react.default.createElement(_Base.AddCardLink, {
  onClick: onClick
}, t('Click to add card'));

exports.default = _default;